<template>
    <div class="tableColumnSetting">
        <draggable :options="options" v-model="data">
            <div v-for="n in data" class="item">
                <i class="iconfont icondrag move"></i>
                <el-checkbox v-model="n.isShow">{{n.label}}</el-checkbox>
                <el-tooltip effect="dark" content="固定在左侧" placement="top">
                    <i class="iconfont icontoggle-left fixed" :class="{ 'active': n.fixed=='left' }" @click="fixed(n,'left')"></i>
                </el-tooltip>
                <el-tooltip effect="dark" content="固定在右侧" placement="top">
                    <i class="iconfont icontoggle-right fixed" :class="{ 'active': n.fixed=='right' }" @click="fixed(n,'right')"></i>
                </el-tooltip>
            </div>
        </draggable>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
export default {
    components: {draggable},
    props: {
        value: {
            type: Array,
            default:function(){
                return []
            }
        },
    },
    data() {
        return {
            options:{
                handle:'.move',
            },
            data:[],
        }
    },
    model: {
        prop: 'value',
        event: 'change'
    },
    watch: {
        value:{
            immediate:true,//初始化立即执行
            handler: function (newVal){
               this.data = newVal//.deepClone();
            }
        },
        data:{
            immediate:true,//初始化立即执行
            handler: function (newVal){
                this.$emit('change', newVal);
            }
        },
    },
    mounted() {},
    methods: {
        fixed(n,key){
            if(n.fixed==key){
                n.fixed = false;
            }else {
                n.fixed = key;
            }
        }
    }
};
</script>
<style scoped lang='scss'>
@import "@/styles/config.scss";
.tableColumnSetting {
    padding: 6px 0;
    max-height: 480px;
    overflow: auto;
    /deep/.item {
        display: flex;
        align-items: center;
        position: relative;
        padding: 6px 70px 6px 6px;
        transition: background-color .2s;
        line-height:20px;
        &:hover {
            background:$themeShallowColor;
        }
        .iconfont {
            opacity:.75;
            &.move {
                cursor: n-resize;
                font-size:14px;
                margin-right:4px;

            }
            &.fixed {
                position:absolute;
                top:6px;
                right:7px;
                cursor:pointer;
                &.active {color:$themeColor;}
                &.icontoggle-left {right:27px;}
            }
        }

        .el-checkbox__input {
            position:relative;
            top:-1px;
        }
    }
}
</style>
